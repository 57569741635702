<template>
  <div
    :class="['image-full-width-wrap', `image-full-width-wrap-width-${width}`]"
  >
    <div class="image-full-width">
      <NuxtLink :to="link?.url" :target="link?.target">
        <picture class="image-full-width-picture">
          <template v-if="inColumnsElement">
            <source
              v-for="imgType in createFormatArray(srcset)"
              :key="imgType.type"
              :type="imgType.type"
              :srcset="imgType.srcsetstring"
              sizes="(min-width: 1280px) 672px, (min-width: 960px) 50vw"
            />
          </template>
          <template v-else>
            <source
              v-for="imgType in createFormatArray(srcset)"
              :key="imgType.type"
              :type="imgType.type"
              :srcset="imgType.srcsetstring"
              :sizes="
                width === 'large'
                  ? '(min-width: 1280xp) 1280xp'
                  : '(min-width: 700px) 700px'
              "
            />
          </template>
          <img
            :src="imageUrl"
            :alt="imageAltText"
            :class="['picture', roundedCorners && 'picture-rounded-corners']"
          />
        </picture>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
  import createFormatArray from "~/mixins/createFormatArray"

  export default {
    name: "ImageFullWidth",
    mixins: [createFormatArray],
    props: {
      inColumnsElement: {
        type: String,
        default: null,
      },

      imageUrl: {
        type: String,
        default: null,
      },
      imageAltText: {
        type: String,
        default: null,
      },
      srcset: {
        type: Array,
        default: () => [],
      },
      roundedCorners: {
        type: Boolean,
        default: true,
      },
      width: {
        type: String,
        default: "large",
        validator: (value) => {
          return ["small", "large"].includes(value)
        },
      },
      link: {
        type: Object,
        default: null,
      },
    },
  }
</script>

<style lang="scss">
  .image-full-width-wrap {
    &-width-small {
      max-width: 50em;
      margin: 0 auto;
    }
  }

  .image-full-width {
    width: 100%;
    position: relative;
  }

  .image-full-width-picture {
    display: block;

    margin: 0;

    .picture {
      display: block;
      height: 100%;
      width: 100%;
      background: $gray-100;
      box-shadow: $shadow;
      @media print {
        display: none;
      }

      &.picture-rounded-corners {
        border-radius: $border-radius-large;
      }
    }
  }

  .image-full-width-caption {
    @include type("sm");
    text-align: right;
    right: 0;
    top: 100%;
    display: block;
    color: $gray-text;
    position: absolute;
  }
</style>
