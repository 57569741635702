<template>
  <Container space-before space-after>
    <ImageFullWidth
      :image-ingredient="imageIngredient"
      :width="width"
      :image-url="imageUrl"
      :image-alt-text="imageAltText"
      :srcset="srcset"
      :in-columns-element="column"
      :link="link"
    />
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    name: "AlchemyImageFullWidth",
    mixins: [AlchemyElement],
    props: {
      element: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    computed: {
      column() {
        return this.$attrs["data-column"]
      },
      imageIngredient() {
        return this.getIngredient("image") || {}
      },
      imageUrl() {
        return this.imageIngredient.value
      },
      imageAltText() {
        return this.imageIngredient.altText
      },
      srcset() {
        return this.imageIngredient?.srcset
      },
      width() {
        return this.getValue("width")
      },
      link() {
        const ingredient = this.getIngredient("image") || {}
        const link = ingredient.linkUrl
          ? {
            target: ingredient?.linkTarget,
            url: ingredient?.linkUrl,
          }
          : null
        return link
      },
    },
  }
</script>
